












import { Component, Vue } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    InputWithLabel,
    ButtonBase,
  },
})
export default class SignIn1 extends Vue {
  private toggleBodyClass(addRemoveClass: string, className: string) {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private mounted(): void {
    this.toggleBodyClass('addClass', 'admin')
  }

  private destroyed(): void {
    this.toggleBodyClass('removeClass', 'admin')
  }
}
